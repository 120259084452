Widgets.DropdownMenu = Class.create(Widgets.Base, {
    name:		'dropdown',

    defaults:	{
        title:		    '\u00A0',
        maxChars:       20,
        value:		    undefined,
        size:			'normal',
        style:			null,
        active:			false,
        label:		    null,
        items:          [],
        width:          'grow',
        onChange:		() => {},
    },

    css: `
        .widget.dropdown { -webkit-user-select: none; }
        .widget.dropdown > label { display: block; font-size: 0.8em; color: #666; margin: 0 0 8px; } 
        .widget.dropdown > button { display: inline-flex; height: 24px; line-height: 24px; cursor: pointer; font-size: 0.8rem; font-weight: var(--font-weight-bold); text-transform: var(--font-transform); text-align: left; border: 1px solid var(--button-border); border-radius: 4px; background: var(--button-background); color: var(--button-text); flex-direction: row; align-items: center; flex-wrap: nowrap; }
        .widget.dropdown > button:hover { background: var(--button-hover-background); } 
        [data-input-mode=mouse] .widget.dropdown > button:focus { outline: none} 
        .widget.dropdown > button:active { background: var(--button-active-background); } 
        .widget.dropdown > button[aria-expanded=true] { background: var(--button-hover-background); } 
        .widget.dropdown > button .title { text-shadow: 0px -1px 0px rgba(255,255,255,0.5); white-space: nowrap; padding: 0 10px 0 10px; flex-grow: 1; }
        .widget.dropdown > button .toggle { text-shadow: none; border-left: 1px solid var(--button-border); margin: 0; padding: 0 5px; color: #888; line-height: 24px; font-family: SalonWidgets; font-size: 12px; content: '⌵'; } 

        .widget.dropdown > button:disabled, .widget.dropdown > button:disabled:hover { background: var(--button-disabled-background); color: var(--button-disabled-text); border-color: var(--button-disabled-border); } 
        .widget.dropdown > button:disabled .toggle { border-color: var(--button-disabled-border); color: var(--button-disabled-text); } 

        .widget.dropdown[data-size=small] > button { height: 22px; line-height: 22px; font-size: 0.7rem; }
        .widget.dropdown[data-size=small] > button .toggle { line-height: 22px; }
        .widget.dropdown[data-style=inline] { margin: 0 6px 0 0; display: inline-block; }
        .widget.dropdown[data-style=columns] > label { display: inline-block; width: 100px; color: #000; font-size: 0.8rem; }
        .widget.dropdown[data-style=columns] > button { margin-left: 12px; }

        .widget.dropdown > button .title [data-color]::before { content: ''; display: inline-block; position: relative; top: 1.5px; margin-right: 8px; width: 12px; height: 12px; background-color: gray; border-radius: 6px; }
        .widget.dropdown > button .title [data-color=lime]::before { background-color: var(--accent-lime-dark-color); }
        .widget.dropdown > button .title [data-color=yellow]::before { background-color: var(--accent-yellow-dark-color); }
        .widget.dropdown > button .title [data-color=orange]::before { background-color: var(--accent-orange-dark-color); }
        .widget.dropdown > button .title [data-color=red]::before { background-color: var(--accent-red-dark-color); }
        .widget.dropdown > button .title [data-color=purple]::before { background-color: var(--accent-purple-dark-color); }
        .widget.dropdown > button .title [data-color=blue]::before { background-color: var(--accent-blue-dark-color); }
        .widget.dropdown > button .title [data-color=teal]::before { background-color: var(--accent-teal-dark-color); }
        .widget.dropdown > button .title [data-color=brown]::before { background-color: var(--accent-brown-dark-color); }

        .widget.menu { z-index: 9000; position: absolute; line-height: 24px; border-radius: 6px; border: 1px solid var(--menu-border); margin; 0; padding: 0; font-size: 0.8rem; font-weight: var(--font-weight-bold); text-transform: var(--font-transform); text-align: left; color: var(--menu-text); box-shadow: 0 0 6px rgba(0,0,0,0.15); filter: drop-shadow(0px 0px 8px rgba(0,0,0,0.25));  }
        .widget.menu ul { list-style-type: none; padding: 4px 0; background: var(--menu-background); border-radius: 4px; box-sizing: border-box; }
        .widget.menu ul:focus { outline: none; }
        .widget.menu ul li { cursor: pointer; white-space: nowrap; margin: 0; padding: 0 10px 0 11px; position: relative; display: flex; align-items: center; }
        .widget.menu ul li em { font-weight: normal; font-style: normal; margin-left: 6px; font-size: 0.75rem; }

        
        [data-input-mode=mouse] .widget.menu ul li[data-type=item]:hover { color: var(--menu-selected-text); border-color: var(--menu-selected-border); background-color: var(--menu-selected-background); }
        [data-input-mode=mouse] .widget.menu ul:not(:hover):focus li[data-type=item][aria-selected=true] { color: var(--menu-selected-text); border-color: var(--menu-selected-border); background-color: var(--menu-selected-background); }
        [data-input-mode=mouse] .widget.menu ul:not(:hover) li[data-type=item]:focus { color: var(--menu-selected-text); border-color: var(--menu-selected-border); background-color: var(--menu-selected-background); }
        [data-input-mode=mouse] .widget.menu ul li[data-type=item]:focus { outline: none; }
        [data-input-mode=keyboard] .widget.menu ul li[data-type=item]:focus { color: var(--menu-selected-text); border-color: var(--menu-selected-border); background-color: var(--menu-selected-background); outline: none; }
        [data-input-mode=keyboard] .widget.menu ul:focus li[data-type=item][aria-selected=true] { color: var(--menu-selected-text); border-color: var(--menu-selected-border); background-color: var(--menu-selected-background); }

        .widget.menu[data-size=small] { font-size: 0.7rem; }
        
        .widget.menu ul li[data-level=1] { padding: 0 10px 0 20px; }
        .widget.menu ul li[data-level=2] { padding: 0 10px 0 30px; }
        .widget.menu ul li[data-level=3] { padding: 0 10px 0 40px; }
        
        .widget.menu ul li[data-type=header] { font-size: 0.75rem; font-weight: normal; padding-top: 10px; cursor: default; }
        .widget.menu ul li[data-type=header]:hover { background: none; color: #000; text-shadow: none; }
        .widget.menu ul li[data-type=separator] { border-top: 1px solid #ddd; margin: 5px 0; cursor: default; }

        .widget.menu ul li[data-type=separator] + li[data-type=header] { padding-top: 0px; }
        .widget.menu ul li[data-type=header]:first-child { padding-top: 0px; }

        .widget.menu ul li[data-type=separator] + li[data-type=separator],
        .widget.menu ul li[data-type=separator]:first-child,
        .widget.menu ul li[data-type=separator]:last-child { display: none; }

        .widget.menu[data-height=fixed] { overflow: hidden; }
        .widget.menu[data-height=fixed] ul { overflow-y: scroll; }
        body[data-scroll=custom] .widget.menu[data-height=fixed] ul { border-right: 6px solid rgba(0,0,0,0); }
        body[data-scroll=custom] .widget.menu[data-height=fixed] ul li { margin-right: 6px; }

        .widget.menu ul li[data-color] { }
        .widget.menu ul li[data-color]::before { content: ''; display: block; width: 12px; height: 12px; margin-right: 8px; background-color: gray; border-radius: 6px; }
        .widget.menu ul li[data-color=lime]::before { background-color: var(--accent-lime-dark-color); }
        .widget.menu ul li[data-color=yellow]::before { background-color: var(--accent-yellow-dark-color); }
        .widget.menu ul li[data-color=orange]::before { background-color: var(--accent-orange-dark-color); }
        .widget.menu ul li[data-color=red]::before { background-color: var(--accent-red-dark-color); }
        .widget.menu ul li[data-color=purple]::before { background-color: var(--accent-purple-dark-color); }
        .widget.menu ul li[data-color=blue]::before { background-color: var(--accent-blue-dark-color); }
        .widget.menu ul li[data-color=teal]::before { background-color: var(--accent-teal-dark-color); }
        .widget.menu ul li[data-color=brown]::before { background-color: var(--accent-brown-dark-color); }

        .widget.menu ul li[data-icon]::before { font-size: 16px; margin-right: 2px; margin-top: 0px; width: 22px; }
        .widget.menu ul li[data-icon='custom-image'] > img { margin-right: 8px; margin-top: 4px; margin-bottom: 4px; }
        .widget.menu ul li[data-icon='employee'] > div {
            display: inline-block;
            width: 24px;
            height: 24px;
            background: #eee;
            border-radius: 12px;
            box-shadow: 0px 0px 2px rgba(0,0,0,0.4), 0px 1px 3px rgba(0,0,0,0.2);
            background-size: 24px 24px;
            margin: 4px 8px 4px 0;    
            
            text-transform: capitalize;
            font-weight: normal;
            color: #bbb;
            text-align: center;
            font-size: 18px;
            line-height: 24px;    
        }

        [data-input-mode=mouse] .widget.menu ul li:hover:before { color: var(--menu-selected-text);  }
        [data-input-mode=mouse] .widget.menu ul:not(:hover):focus li[aria-selected=true]:before { color: var(--menu-selected-text); }
        [data-input-mode=mouse] .widget.menu ul:not(:hover) li:focus:before { color: var(--menu-selected-text); }
        [data-input-mode=keyboard] .widget.menu ul li:focus:before { color: var(--menu-selected-text); }
        [data-input-mode=keyboard] .widget.menu ul:focus li[aria-selected=true]:before { color: var(--menu-selected-text); }

        .widget.menu ul li[data-type=item][aria-disabled],
        .widget.menu ul li[data-type=item][aria-disabled]:hover { color: var(--menu-disabled-text); background-color: var(--menu-disabled-background); }
        .widget.menu ul li[data-type=item][aria-disabled]:before { color: var(--menu-disabled-text); }
    `,

    initWidget: function() {
        if (this.options.style) this.dataset.style = this.options.style; 
        if (this.options.size) this.dataset.size = this.options.size; 
        if (this.options.width) this.dataset.width = this.options.width; 

        if (this.options.name) {
			this.dataset.name = this.options.name;
		}

        if (this.options.label) {
            var label = new Element('label').update(this.options.label);
            this.insert(label);
        }

        this.defineProperty("title", {
            get:	() => {
                if (typeof this.options.items == 'function') {
                    this.internal.items = this.flatten(this.options.items);
                }

                let title;
                let selected = this.internal.items.filter(i => i.type == 'item' && i.value == this.internal.value).pop();
                
                if (selected && selected.title) {
                    title = escapeHTML(selected.title);
                
                    if (title.length > this.options.maxChars) {
                        title = title.substr(0, this.options.maxChars) + '...';
                    }

                    if (selected.color) {
                        title = '<span data-color="' +  selected.color + '">' + title + '</span>';
                    }

                    return title;
                }

                title = escapeHTML(this.options.title);

                if (title.length > this.options.maxChars) {
                    title = title.substr(0, this.options.maxChars) + '...';
                }

                return title;
            }
        });

        this.defineProperty("valid", {
            get:    () => typeof this.internal.value !== 'undefined',
        });

        this.defineProperty("value", {
            get:    () => this.internal.value,
            set:	value => { this.internal.value = value; this.internal.title.update(this.title); }
        });

        this.internal.value = this.options.value;
        this.internal.map = new Map();
        this.internal.items = [];

        if (typeof this.options.items != 'function') {
            this.internal.items = this.flatten(this.options.items);
        }

        if (typeof this.internal.value === 'undefined') {
            let selected = this.internal.items.filter(i => i.selected).pop();
            if (selected) {
                this.internal.value = selected.value;
            }
        }
        
        this.internal.keyboard = {
            buffer: '', timeout: null
        };

        this.buildWidget();
    },  

    stateChange: function() {
        if (this.internal.widget) {
            this.internal.widget.disabled = ! this.enabled;
        }

        if (!this.enabled) {
            this.close();
        }
    },




    /* Public functions */

    toggle: function() {
        if (this.internal.widget.getAttribute('aria-expanded') == 'false') {
            this.open();
        }
        else {
            this.close();
        }
    },

    open: function() {
        if (!this.enabled) {
            return;
        }

        let top = 0;
        let scroll = 0;
        let height = 0;
        let lineWidth = 1;
        let paddingDefault = 4;
        let paddingBottom = paddingDefault;
        let paddingTop = paddingDefault;


        /* Create menu */

        let buttonBounds = this.internal.widget.getBoundingClientRect();

        let menu = this.buildMenu();
        menu.firstChild.style.left = (buttonBounds.left - lineWidth) + 'px';
        menu.firstChild.style.top = buttonBounds.top + 'px';
        menu.firstChild.style.visibility = 'hidden';
        this.internal.menu = document.body.appendChild(menu.firstChild);


        /* Get the bounds of the menu */

        let menuBounds = this.internal.menu.getBoundingClientRect();


        /* Get offset of item that should be positioned over the button */
        
        let selectedOffset = null;
        let selected = this.internal.menu.querySelector('[aria-selected=true]');

        if (selected) {
            selectedOffset = selected.offsetTop;
        }

        
        /* Determine maximum space above and below button */
        
        let spaceAbove = 400;
        let spaceBelow = 400;

        if (buttonBounds.top - spaceAbove < 20) {
            spaceAbove = buttonBounds.top - 20;
        }

        if (buttonBounds.top + spaceBelow > window.innerHeight - 20) {
            spaceBelow = window.innerHeight - 20 - buttonBounds.top;
        }


        /* Determine maximum height */

        let maximumHeight = spaceAbove + spaceBelow;


        /* Option A: Position the menu based on selection offset */

        if (selected) {
            
            /* A1: Menu is too tall, use all available space */

            if (menuBounds.height > maximumHeight) {
                height = maximumHeight;
                top = buttonBounds.top - spaceAbove;

                /* A1a: Can we scroll to the selected item? */

                let selectedMinimum = top + selectedOffset - (menuBounds.height - maximumHeight);
                let selectedMaximum = top + selectedOffset;

                if (buttonBounds.top >= selectedMinimum && buttonBounds.top <= selectedMaximum) {
                    scroll = selectedOffset - spaceAbove;
                }

                /* A1b: We can't scroll to it... */

                else {
                    let distanceWhenScrollToTop = Math.abs(selectedMaximum - buttonBounds.top);
                    let distanceWhenScrollToBottom = Math.abs(selectedMinimum - buttonBounds.top);

                    /* Distance is smaller when scrolled to top */

                    if (distanceWhenScrollToTop < distanceWhenScrollToBottom) {
                        paddingTop = paddingDefault + distanceWhenScrollToTop;
                    }
                    
                    /* Distance is smaller when scrolled to bottom */

                    else {
                        paddingBottom = paddingDefault + distanceWhenScrollToBottom + lineWidth + lineWidth;
                        scroll = distanceWhenScrollToTop;
                    }
                }
            }

            /* A2: On first glance there is enough space */

            else {
                /* A2a: There is enough space above and below to reposition */

                if (selectedOffset < spaceAbove && menuBounds.height - selectedOffset < spaceBelow) {
                    top = buttonBounds.top - selectedOffset;
                }

                /* A2b: There is enough space above, but not enough below, add padding above */

                else if (selectedOffset < spaceAbove) {
                    top = buttonBounds.top + spaceBelow - menuBounds.height;
                    paddingTop = paddingDefault - (spaceBelow - menuBounds.height + selectedOffset);
                    height = menuBounds.height;
                }

                /* A2c: There is enough space below, but not enough above, add padding above */

                else if (menuBounds.height - selectedOffset < spaceBelow) {
                    top = buttonBounds.top - spaceAbove;
                    paddingBottom = paddingDefault + (top + selectedOffset) - buttonBounds.top + lineWidth + lineWidth;
                    height = menuBounds.height;
                    scroll = paddingBottom - paddingDefault; 
                }
            }
        }

        /* Option B: Position the menu based on limits only */

        else {

            /* B1: Menu is too tall, use all available space */

            if (menuBounds.height > maximumHeight) {
                height = maximumHeight;
                top = buttonBounds.top - spaceAbove;
            }

            /* B2: Menu fits in available space, find optimal top position */

            else {
                let halfHeight = menuBounds.height / 2;

                /* Plenty of space above and below, just center vertically based on the button */

                if (halfHeight < spaceAbove && halfHeight < spaceBelow) {
                    top = buttonBounds.top - halfHeight;
                }

                /* Can't center vertically, so find the side with the least space and base it on that */

                else {
                    /* Less space below */

                    if (spaceAbove > spaceBelow) {
                        top = buttonBounds.top - (menuBounds.height - spaceBelow);
                    }

                    /* Less space above */

                    else {
                        top = buttonBounds.top - spaceAbove;
                    }
                }
            }
        }


        /* Limit padding, by decreasing height */

        if (height && paddingTop > 150) {
            let offset = paddingTop - 150;

            paddingTop -= offset;
            height -= offset;
            top += offset;
        }

        if (height && paddingBottom > 150) {
            let offset = paddingBottom - 150;

            paddingBottom -= offset;
            height -= offset;
        }


        /* Apply styles */

        this.internal.menu.style.top = (top - lineWidth) + 'px';

        if (paddingBottom > 0) {
            this.internal.menu.firstChild.style.paddingBottom = paddingBottom + 'px';
        }

        if (paddingTop > 0) {
            this.internal.menu.firstChild.style.paddingTop = paddingTop + 'px';
        }

        if (height) {
            this.internal.menu.dataset.height = 'fixed';
            this.internal.menu.firstChild.style.height = height + 'px';

            if (System.Engine.WebKit) {
                requestAnimationFrame(() => {
                    this.internal.menu.firstChild.scrollTop = scroll;
                });
            }
            else {
                this.internal.menu.firstChild.scrollTop = scroll;
            }
        }

        this.internal.menu.style.visibility = 'visible';
        this.internal.menu.firstChild.focus();


        /* Register menu */

        Widgets.registerSheet(this, [ this.internal.widget, this.internal.menu ]);

        this.internal.widget.setAttribute('aria-expanded', 'true');

        this.internal.keyboard.buffer = '';
        this.internal.keyboard.timeout = null;
    },

    close: function() {
        this.internal.widget.setAttribute('aria-expanded', 'false');

        if (this.internal.menu) {
            this.internal.menu.remove();
        }
    },

    clear: function() {
        this.options.items = [];
        this.internal.items = [];
        this.internal.map.clear();
        
        this.value = undefined;
    },

    add: function(item) {
        let items = this.flattenItem(item, 0);

        this.options.items.push(item);
        this.internal.items = this.internal.items.concat(items);

        let selected;

        if (typeof this.internal.value !== 'undefined') {
            selected = items.filter(i => i.selected || i.value == this.internal.value).pop();
        } else {
            selected = items.filter(i => i.selected).pop();
        }

        if (selected) {
            this.value = selected.value;
        }
    },

    update: function() {
        this.internal.items = this.flatten(this.options.items);
        this.internal.title.update(this.title);
    },



    /* Select */

    selectFirst: function() {
        this.internal.items = this.flatten(this.options.items);

        let first = this.internal.items.filter(i => i.type == 'item' && i.enabled).shift();
        
        if (first) {
            this.selectByItem(first);
        }
    },


    selectByElement: function(element) {
        let item = this.internal.map.get(element); 

        if (item) {
            this.selectByItem(item);
        }

        this.close();
    },

    selectByItem: function(item) {
        this.value = item.value;

        if (item.onSelect) {
            item.onSelect();
        }

        if (this.options.onChange) {
            this.options.onChange(this.value);
        }
    },




    /* Render widget */

    buildWidget: function() {
        this.internal.widget = new Element('button');
        this.internal.widget.disabled = ! this.enabled;
        this.internal.widget.setAttribute('aria-expanded', 'false');
        this.internal.widget.setAttribute('aria-haspopup', 'menu');
        this.insert(this.internal.widget);

        this.internal.widget.addEventListener('keydown', this.handleWidgetKeyDown.bind(this));
        this.internal.widget.addEventListener('keyup', this.handleWidgetKeyUp.bind(this));
        this.internal.widget.addEventListener('click', this.handleWidgetMouseClick.bind(this));

        this.internal.title = new Element('div').update(this.title);
        this.internal.title.classList.add('title');
        this.internal.widget.appendChild(this.internal.title);

        if (this.options.width == 'grow') {
            let maximumLength = Math.min(this.options.maxChars + 3, Math.max(...this.internal.items.map(i => i.title ? i.title.length : 0)));
            this.internal.title.style.minWidth = (0.85 * maximumLength) + 'ch';
        }

        this.internal.toggle = new Element('div').update('⌵');
        this.internal.toggle.classList.add('toggle');
        this.internal.widget.appendChild(this.internal.toggle);
    },


    /* Handle widget events */

    handleWidgetKeyUp: function(event) {
        if (event.key == 'ArrowUp' || event.key == 'ArrowDown') {
            event.stopPropagation();
        }
    },

    handleWidgetKeyDown: function(event) {
        if (event.key == 'ArrowUp') {
            event.stopPropagation();

            if (!this.enabled) {
                return;
            }

            let index = this.internal.items.findIndex(i => i.value == this.value);
            let previous = this.internal.items.filter((i, idx) => idx < index && i.type == 'item' && i.enabled).pop();
            if (previous) {
                this.selectByItem(previous);
            }
        }

        if (event.key == 'ArrowDown') {
            event.stopPropagation();

            if (!this.enabled) {
                return;
            }

            let index = this.internal.items.findIndex(i => i.value == this.value);
            let next = this.internal.items.filter((i, idx) => idx > index && i.type == 'item' && i.enabled).shift();
            if (next) {
                this.selectByItem(next);
            }
        }
    },

    handleWidgetMouseClick: function(event) {
        event.stopPropagation();

        this.toggle();

        if (this.internal.menu) {
            if (event.x == 0 && event.y == 0) {
                Widgets.inputMode = 'keyboard';
            } else {
                Widgets.inputMode = 'mouse';
            }
        }
    },





    /* Render menu */

    buildMenu: function() {
        this.internal.items = this.flatten(this.options.items);

        let fragment = document.createDocumentFragment();

        let panel = new Element('div', { 'class': 'widget menu' });
        if (this.options.size) panel.dataset.size = this.options.size; 
        fragment.appendChild(panel);

        let list = new Element('ul');
        list.tabIndex = -1;
        list.role = 'menu';
        panel.appendChild(list);

        list.addEventListener('mousemove', this.handleMenuMouseMove.bind(this));
        list.addEventListener('click', this.handleMenuMouseClick.bind(this));
        list.addEventListener('keypress', this.handleMenuKeyPress.bind(this));
        list.addEventListener('keyup', this.handleMenuKeyUp.bind(this));
        list.addEventListener('keydown', this.handleMenuKeyDown.bind(this));

        this.internal.items.forEach(item => {
            if (item.type == 'header') {
                let element = new Element('li').update(escapeHTML(item.title));
                element.dataset.type = 'header';
                list.appendChild(element);
            }

            else if (item.type == 'separator') {
                let element = new Element('li');
                element.dataset.type = 'separator';                
                list.appendChild(element);
            }

            else {
                let title = escapeHTML(item.title);

                if (item.subtitle) {
                    title += `<em> ${escapeHTML(item.subtitle)}</em>`;
                }

                let element = new Element('li').update(title);
                element.dataset.type = 'item';                
                element.dataset.level = item.level;
                element.role = 'menuitem';

                if (item.enabled === false) {
                    element.ariaDisabled = 'true'
                } else {
                    element.tabIndex = 0;
                }

                list.appendChild(element);

                if (item.color != '') {
                    element.dataset.color = item.color;
                }

                if (item.value == this.value) {
                    element.setAttribute('aria-selected', 'true');
                }

                if (item.icon) {
                    if (typeof item.icon == 'object') 
                    {
                        if (item.icon.id) {
                            element.dataset.icon = item.id;
                        }

                        if (item.icon.character) {
                            element.dataset.icon = 'custom-character';
                            element.dataset.iconCharacter = item.icon.character;
                        }
        
                        if (item.icon.color) {
                            element.dataset.iconColor = item.icon.color;
                        }

                        if (item.icon.src) {
                            element.dataset.icon = 'custom-image';

                            let icon = new Image();
                            icon.width = item.icon.width || item.icon.size || 18;
                            icon.height = item.icon.height || item.icon.size || 18;
                            icon.alt = '';

                            let found = item.icon.src.match(/([a-z]+)#(.+)/iu);
                            if (found) {
                                icon.src = `../../../assets/icons/${found[1]}/icons/${found[2]}.svg`;
                            } else {
                                icon.src = item.icon.src;    
                            }

                            element.insertBefore(icon, element.firstChild);
                        }

                        if (item.icon.employee) {
                            element.dataset.icon = 'employee';

                            let size = System.Features.Retina ? '42' : '21';
                            let photo = new Element('div', { 'class': 'photo' });

                            if (item.icon.employee.photo) {
                                photo.style.backgroundImage = `url(${item.icon.employee.photo}?w=${size}&h=${size}&fit=crop)`;
                            }
                            else {
                                photo.textContent = item.icon.employee.initial;
                            }
                            
                            element.insertBefore(photo, element.firstChild);
                        }
                    } else {
                        element.dataset.icon = item.icon;
                    }
                }

                item.element = element;
                this.internal.map.set(element, item);
            }
        });

        return fragment;
    },


    /* Handle menu events */

    handleMenuKeyPress: function(event) {
        let menu = event.target.closest('ul');

        if (event.key == 'Enter') {
            let focus = menu.querySelector('li:focus');

            if (focus) {
                this.selectByElement(focus);
            }
            else {
                this.close();
            }

            this.internal.widget.focus();
        }

        else {
            this.handleMenuTextInput(event.key);
        }
    },

    handleMenuKeyUp: function(event) {
        if (event.key == 'ArrowUp' || event.key == 'ArrowDown') {
            event.stopPropagation();
        }

        if (event.key == 'Escape') {
            event.stopPropagation();
            this.close();
            this.internal.widget.focus();
        }
    },

    handleMenuKeyDown: function(event) {
        Widgets.inputMode = 'keyboard';

        let menu = event.target.closest('ul');

        if (event.key == 'ArrowUp') {
            event.stopPropagation();

            let base = menu.querySelector('li:focus');

            if (!base) {
                base = menu.querySelector('li[aria-selected=true]');
            }

            if (!base) {
                base = menu.querySelector('li[data-type=item]');
                base.focus();
                return;
            }

            if (base) {
                while (base = base.previousElementSibling) {
                    if (base.dataset.type == 'item' && base.ariaDisabled != 'true') {
                        base.focus();
                        return;
                    }
                }
            } 
        }

        if (event.key == 'ArrowDown') {
            event.stopPropagation();

            let base = menu.querySelector('li:focus');

            if (!base) {
                base = menu.querySelector('li[aria-selected=true]');
            }

            if (!base) {
                base = menu.querySelector('li[data-type=item]');
                base.focus();
                return;
            }

            if (base) {
                while (base = base.nextElementSibling) {
                    if (base.dataset.type == 'item' && base.ariaDisabled !== 'true') {
                        base.focus();
                        return;
                    }
                }
            }
        }
    },

    handleMenuMouseClick: function(event) {
        let element = event.target.closest('li');

        if (element && element.ariaDisabled !== 'true') {
            this.selectByElement(element);
        }
        
        event.stopPropagation();
    },

    handleMenuMouseMove: function(event) {
        Widgets.inputMode = 'mouse';
    },


    /* Handle menu text input */

    handleMenuTextInput: function(key) {
        if (this.internal.keyboard.timeout) {
            window.clearTimeout(this.internal.keyboard.timeout);
        }

        this.internal.keyboard.buffer += key;
        this.internal.keyboard.timeout = window.setTimeout(() => {
            this.internal.keyboard.buffer = '';
            this.internal.keyboard.timeout = null;
        }, 2 * 1000);

        let found = this.internal.items.filter(i => i.type == 'item' && i.enabled !== false && String(i.title).toLowerCase().startsWith(this.internal.keyboard.buffer.toLowerCase()) );
        if (found.length) {
            found[0].element.focus();
        }
    },




    /* Prepare and flatten data */

    flatten: function(items, level) {
        if (typeof level === 'undefined') {
            level = 0;
        }

        let result = [];

        if (typeof items == 'function') {
            items = items.apply(this)
        }

        items.forEach(item => {
            result = result.concat(this.flattenItem(item, level));
        });

        return result;
    },

    flattenItem: function(item, level) {
        let result = [];

        item = Object.assign({
            type:		'item',
            title: 		'',
            color:		'',
            visible:	true,
            level:		level,
            selected: 	false,
            enabled:	true,
            onSelect:	null
        }, item || {});

        if (item.visible) {
            if (typeof item.items !== 'undefined') {
                item.type = 'header';
                result.push(item);

                result = result.concat(this.flatten(item.items, level + 1));
            }

            else {
                result.push(item);
            }
        }

        return result;
    }
});
